import './_learning-programs-filter-block.scss';
import {ProgramFilters} from 'JS/class/ProgramFilters';
import salvattore from 'JS/lib/salvattore.js';

const learningProgramsFilterBlock = function () {
    const blocks = document.querySelectorAll('section.learning_programs_filter');

    blocks.forEach(block => {
        const id = block.getAttribute('id');
        const nonce = block.querySelector('#techin_nonce').value;
        const select = block.querySelector('.select-program-area');
        const searchWrap = block.querySelector('.input-text');
        const searchInput = searchWrap.querySelector('.search-field');
        const iconClear = searchWrap.querySelector('.clear-icon');
        const postsWrap = block.querySelector('.posts-wrap');
        const loadMore = block.querySelector('.js-load-more-posts');
        const buttons = block.querySelectorAll('.btn-wrap a');
        const currentUrl = window.location.href;
        const programFilters = new ProgramFilters(id, nonce, select);

        /**
         * Handles the filter buttons its state
         * and fetches the subcategories on click.
         */
        buttons.forEach(button => {
            button.addEventListener('click', function () {
                let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?cat=${this.getAttribute('data-slug')}`;
                if (this.classList.contains('active')) {
                    newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                }

                buttons.forEach(btn => {
                    if (this === btn && !this.classList.contains('active')) {
                        this.classList.add('active');
                    } else {
                        btn.classList.remove('active');
                    }
                });

                programFilters.updateTaxQuery(this.getAttribute('data-slug'));
                programFilters.args.childCategories = [];
                programFilters.args.page = 1;

                programFilters.fetchSubcategories('category').then((response) => {
                    programFilters.populateSelect(response, true);
                });


				// Update the url with new category slug.
				history.pushState( null, null, newUrl );
			} );
		} );

        /**
         * Handles the select element.
         *
         * On select event resets the page and sets the current child category.
         */
        programFilters.select.el.addEventListener('change', function () {
            programFilters.args.childCategories = this.value;
            programFilters.args.page = 1;
        });


        /**
         * Handles the search input element.
         *
         * Sets the search value and page arguments when user types in the element.
         * Timeout reduce the frequency of requests to the server.
         */
        let timerId;
        searchInput.addEventListener('input', function () {
            // Clear any previous timers
            clearTimeout(timerId);

            const inputValue = this.value;
            // Set a timer to delay the call
            timerId = setTimeout(() => {
                programFilters.args.s = inputValue;
                programFilters.args.page = 1;
            }, 500);

            if (inputValue.length) {
                searchWrap.classList.add('has-value');
            } else {
                searchWrap.classList.remove('has-value');
            }

        });

        /**
         * Prevent search form execution on presing enter.
         */
        searchInput.onkeydown = function (e) {
            if (e.which === 13) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        };

        /**
         * Clears the search input value.
         */
        iconClear.addEventListener('click', function (e) {
            e.preventDefault();
            searchInput.value = '';
            programFilters.args.s = '';
            programFilters.args.page = 1;
            searchWrap.classList.remove('has-value');
        });

        /**
         * Handles the load more button.
         *
         * Adds a page number each time when user clicks on the load more button.
         */
        loadMore.addEventListener('click', function (e) {
            e.preventDefault();
            programFilters.args.page++;
        });

        /**
         * Handles the state change on programFilters arguments.
         *
         * Fetch posts every time an argument state changes.
         */
        document.addEventListener(id + '_filterArgumentsChanged', (event) => {
            programFilters.fetchPosts().then((response) => {
                let tempContainer = document.createElement('div');
                tempContainer.innerHTML = response.html;
                let cardElements = tempContainer.getElementsByClassName('card-learning-program');

                if (cardElements.length > 1) {
                    postsWrap.classList.add('add-helper-line');
                } else {
                    postsWrap.classList.remove('add-helper-line');
                }

                if (programFilters.args.page > 1) {
                    for (let i = 0; i < cardElements.length; i++) {
                        let cardElement = cardElements[i].cloneNode(true); // Create a clone of the card element
                        salvattore['append_elements'](postsWrap, [cardElement]);
                    }
                } else {
                    postsWrap.innerHTML = response.html;
                    salvattore.registerGrid(postsWrap);
                    window.scrollTo({
                        top: postsWrap.offsetTop - 200,
                        behavior: 'smooth'
                    });
                }

                if (response.no_more) {
                    loadMore.classList.add('hidden');
                } else {
                    loadMore.classList.remove('hidden');
                }

            });
        });

        /**
         * Updates programFilters based on the 'cat' query parameter in the current URL.
         */
        const updateQueryFromUrl = () => {
            if (currentUrl.includes('?cat')) {

                const activeButton = Array.from(buttons).filter(
                    button => button.classList.contains('active')
                );

                programFilters.updateTaxQuery(activeButton[0].getAttribute('data-slug'));
                programFilters.args.childCategories = [];
                programFilters.args.page = 1;
            }
        };

        updateQueryFromUrl();

    });
};

document.addEventListener('DOMContentLoaded', function () {
    learningProgramsFilterBlock()
}, false);

window.addEventListener('acf/loaded/learning-programs-filter-block', function (e) {
    learningProgramsFilterBlock()
});